@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
// Padding for the containers
$container-padding-x: 0.75rem; // Container padding

// Basic container styles
@mixin make-container {
  width: 100%;
  padding-right: var(--q-gutter-x, $container-padding-x);
  padding-left: var(--q-gutter-x, $container-padding-x);
  margin-right: auto;
  margin-left: auto;
}

$sizes: (
  "xs": 0,
  "sm": $breakpoint-xs + 1,
  "md": $breakpoint-sm + 1,
  "lg": $breakpoint-md + 1,
  "xl": $breakpoint-lg + 1,
); // Bootstrap Container Integration

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm,
.container-xs {
  @include make-container();
}

@media (min-width: map-get($sizes, "xs")) {
  .container-xs,
  .container {
    max-width: 560px;
  }
}
@media (min-width: map-get($sizes, "sm")) {
  .container-sm,
  .container-xs,
  .container {
    max-width: 980px;
  }
}
@media (min-width: map-get($sizes, "md")) {
  .container-md,
  .container-sm,
  .container-xs,
  .container {
    max-width: 1360px;
  }
}
@media (min-width: map-get($sizes, "lg")) {
  .container-lg,
  .container-md,
  .container-sm,
  .container-xs,
  .container {
    max-width: 1800px;
  }
}
